<template>
  <div class="faceRecognition-wrap">
    <div class="content">
      <div class="header">
        <img src="@/assets/imgs/face.png" alt />
        <!-- <div class="tip">查看录制须知</div> -->
      </div>
      <div class="explain">
        <p>
          请使用
          <br />
          <span>前置摄像头</span>
        </p>
        <p>
          请用
          <span>普通话</span>读一遍 <br />下方数字
        </p>
        <p>
          视频时长
          <br />
          <span>3-5秒</span>
        </p>
      </div>
      <ul class="dot">
        <li class="dot-item">1</li>
        <li class="dot-line"></li>
        <li class="dot-item">2</li>
        <li class="dot-line"></li>
        <li class="dot-item">3</li>
      </ul>
      <div class="num">{{ randomNum }}</div>
    </div>
    <input
      type="file"
      accept="video/*"
      apture="camcorder"
      ref="input"
      v-show="false"
      @change="onChange"
    />
    <div class="footer">
      <div class="btn" @click="begin">开始录制</div>
      <div class="des">
        不方便录制视频？
        <span @click="goHome">尝试其他认证方式</span>
      </div>
    </div>
  </div>
</template>
<script>
  import Api from '@/api'
  import { Toast } from 'vant'

  export default {
    data() {
      return {
        isShowVideo: false,
        taskId: null,
        randomNum: null,
      }
    },
    created() {
      this.tokenInfo = this.$jsonPase(
        window.sessionStorage.getItem('tokenInfo')
      )
      this.getFaceChannel()
    },
    methods: {
      getFaceChannel() {
        const { creditMsg } = this.$store.state
        Api.getFaceChannel({
          userId: creditMsg.userId,
        }).then(res => {
          this.taskId = res.taskId
          this.idCardOcrVerify()
        })
      },
      idCardOcrVerify(taskId) {
        const { creditMsg } = this.$store.state
        Api.idCardOcrVerify({
          taskId: this.taskId,
          idCard: creditMsg.userIdCardNo,
          userName: creditMsg.userFullName,
          userId: creditMsg.userId,
        }).then(res => {
          this.randomNum = res.randomNum
        })
      },
      livenessVerify() {
        const { creditMsg } = this.$store.state
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        Api.livenessVerify({
          taskId: this.taskId,
          videoBase64: this.videoBase64,
          videoType: this.videoType,
          userId: creditMsg.userId,
        })
          .then(res => {
            this.$router.push('/authenticationSuccess')
          })
          .catch(() => {
            this.$router.push({
              path: '/authenticationError',
              query: {
                type: 'face',
              },
            })
          })
          .finally(() => {
            toast.clear()
          })
      },
      begin() {
        this.$refs.input.click()
      },
      onChange(event) {
        const file = event.target.files[0]
        this.videoType = file.type
        const reader = new FileReader()
        const rs = reader.readAsDataURL(file)
        reader.onload = e => {
          this.videoBase64 = e.target.result
          this.livenessVerify()
        }
      },
      goHome() {
        this.$router.push('/auth')
      },
    },
  }
</script>
<style lang="less" scoped>
  .faceRecognition-wrap {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    .content {
      // padding-bottom: 108px;
      flex: 1;
      .header {
        width: 375px;
        height: 300px;
        margin-bottom: 16px;
        position: relative;
        z-index: -1;
        video {
          width: 375px;
          height: 300px;
        }
        .tip {
          position: absolute;
          top: 16px;
          right: 16px;
          width: 72px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(22, 118, 255, 1);
          line-height: 17px;
          text-decoration: underline;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .explain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 21px;
        padding-right: 28px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(109, 119, 144, 1);
        line-height: 22px;
        margin-bottom: 5px;
        text-align: center;
        span {
          color: rgba(241, 86, 67, 1);
        }
      }
      .dot {
        padding: 0 48px;
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        .dot-item {
          width: 15px;
          height: 15px;
          background: rgba(226, 228, 234, 1);
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          line-height: 15px;
          text-align: center;
          border-radius: 50%;
        }
        .dot-line {
          width: 109px;
          border-top: 1px dashed rgba(215, 221, 228, 1);
          margin: 0 4px;
        }
      }
      .num {
        width: 135px;
        height: 68px;
        font-size: 56px;
        font-family: DIN-Medium, DIN;
        font-weight: 500;
        color: rgba(67, 71, 81, 1);
        line-height: 68px;
        letter-spacing: 2px;
        margin: 0 auto;
        margin-bottom: 27px;
      }
    }
    .footer {
      // position: absolute;
      // bottom: 0;
      // left: 0;
      width: 375px;
      height: 108px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
      padding-top: 10px;
      .btn {
        width: 327px;
        height: 50px;
        background: rgba(22, 118, 255, 1);
        box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
        border-radius: 25px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 50px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 16px;
      }
      .des {
        height: 16px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(109, 119, 144, 1);
        line-height: 16px;
        text-align: center;
        span {
          color: rgba(22, 118, 255, 1);
        }
      }
    }
  }
</style>
